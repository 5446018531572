import { useParams } from "react-router-dom";
import MainSection from './components/MainSection';
import ProfileSetUp from './components/ProfileSetUp';
import useApplicationContext from './context/ApplicationContext';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { isProfileSetUp } = useApplicationContext();
  const { jobId } = useParams();

  return (
    <div className="h-full">
      {/* Add ToastContainer here */}
      <ToastContainer 
        position="top-right" // You can adjust the position
        autoClose={3000} // Time in ms before the toast disappears
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
      {(!!jobId || !!isProfileSetUp)
        ? <MainSection />
        : <ProfileSetUp />
      }
    </div>
  );
}

export default App;
