import React, { useRef, useState, useEffect } from "react";
import {
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import { toast } from "react-toastify";

export default function ShareButtonsMenu({ url, title, className = "" }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null); // Reference for the menu

  // Close menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false); // Close the menu if clicked outside
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Copy URL to clipboard
  const handleCopyLink = (e) => {
    e.stopPropagation(); // Prevent triggering parent handlers
    navigator.clipboard
      .writeText(url)
      .then(() => toast.success("URL copied to clipboard!"))
      .catch(() => toast.error("Failed to copy the URL."));
  };

  // Toggle menu visibility
  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  return (
    <div className={`relative ${className}`}>
      {/* Share Button */}
      <button
        onClick={toggleMenu}
        className="p-2 text-gray-600 hover:text-gray-800"
        aria-label="Share options"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="24"
          height="24"
        >
          <path
            fill="currentColor"
            d="M71.8 446.6C101 469.9 128 480 128 480s-20.2-20.2-28.5-52.6C97.3 419 96 409.9 96 400c0-61.9 50.1-112 112-112h48 32v32 32 32h32L488.3 229.7 512 208l-23.7-21.7L320 32H288V64 96v32H256 176C78.8 128 0 206.8 0 304c0 69.2 37.5 115.1 71.8 142.6zm-7.7-52.3C45.9 371.8 32 342.2 32 304c0-79.5 64.5-144 144-144H288h32V128 75.4L464.6 208 320 340.6V288 256H288 208c-77.6 0-140.9 61.4-143.9 138.3z"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isMenuOpen && (
        <div
          ref={menuRef}
          onClick={(e) => e.stopPropagation()}
          className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded-lg shadow-lg z-50"
        >
          <div className="flex flex-col p-2 space-y-2">
            {/* Email Share */}
            <EmailShareButton url={url} subject={title}>
              <p className="flex items-center text-gray-800 cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-md">
                <span className="material-icons mr-2">email</span> Email
              </p>
            </EmailShareButton>

            {/* WhatsApp Share */}
            <WhatsappShareButton url={url} title={title}>
              <p className="flex items-center text-gray-800 cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-md">
                <span className="material-icons mr-2">chat</span> WhatsApp
              </p>
            </WhatsappShareButton>

            {/* Facebook Share */}
            <FacebookShareButton url={url} quote={title}>
              <p className="flex items-center text-gray-800 cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-md">
                <span className="material-icons mr-2">facebook</span> Facebook
              </p>
            </FacebookShareButton>

            {/* Twitter/X Share */}
            <TwitterShareButton url={url} title={title}>
              <p className="flex items-center text-gray-800 cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-md">
                <span className="material-icons mr-2">X</span> Twitter
              </p>
            </TwitterShareButton>

            {/* Copy Link */}
            <button
              onClick={handleCopyLink}
              className="flex items-center text-gray-800 cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-md"
            >
              <span className="material-icons mr-2">link</span> Copy Link
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
