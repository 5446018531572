import React, { useEffect, useState } from "react";
import useApplicationContext from "../../context/ApplicationContext";
import {
  applyJob,
  getStorageItem,
  removeItemFromStorageItemWithArrayValue,
  storageKey,
  updateStorageItemWithArrayValue,
} from "../../helpers/utils";
import Icons from "../../icons";
import HtmlRenderer from "../blocks/HtmlRenderer";
import JobIsExternal from "../jobs/JobIsExternal";
import JobLogoAndAddress from "../jobs/JobLogoAndAddress";
import JobTags from "../jobs/JobTags";
import AddResumeModal from "../modals/AddResumeModal";
import JobExternalModal from "../modals/JobExternalModal";
import ModalWrapper from "./ModalWrapper";

const JobDetailModal = (props) => {
  const {
    data = {},
    isVisible,
    onJobApplied = () => { },
    onCrossClick = () => { },
  } = props;
  const { user } = useApplicationContext();
  const [isApplying, setIsApplying] = useState(false);
  const [showExternalJobModal, setShowExternalJobModal] = useState(false);
  const [showAddResumeModal, setShowAddResumeModal] = useState(false);
  const [savedJob, setSavedJob] = useState(false);
  const [appliedJob, setAppliedJob] = useState(false);

  useEffect(() => {
    let _savedJobs = getStorageItem(storageKey.SAVED_JOBS);
    let _appliedJobs = getStorageItem(storageKey.APPLIED_JOBS);
    let isSaved = _savedJobs
      ? _savedJobs.findIndex((i) => i.id === data?.id
      ) !== -1
      : false;
    let isApplied = _appliedJobs
      ? _appliedJobs.findIndex((i) => i.id === data?.id) !== -1
      : false;
    setSavedJob(isSaved);
    setAppliedJob(isApplied);
  }, [data?.id, isVisible]);

  const handleSaveJob = async () => {
    if (!savedJob) {
      updateStorageItemWithArrayValue(storageKey.SAVED_JOBS, data);
    } else {
      removeItemFromStorageItemWithArrayValue(storageKey.SAVED_JOBS, data);
    }
    setSavedJob(!savedJob);
  };

  // const handleApplyJob = async (e) => {
  //   e.stopPropagation();
  //   if (data.isExternalJob) {
  //     const vejm = true;
  //     if (!vejm === false) {
  //       setShowExternalJobModal(true);
  //       // data?.jobUrl && window.open(data.jobUrl, "_blank");
  //     } else {
  //       setShowExternalJobModal(true);
  //     }
  //   }
  //    else {
  //     if (!user?.resume) {
  //       setShowAddResumeModal(true);
  //     } else {
  //       setIsApplying(true);
  //       let res = await applyJob({ job: data });
  //       if (res.status === "OK") {
  //         updateStorageItemWithArrayValue(storageKey.APPLIED_JOBS, {
  //           ...data,
  //           appliedDate: res.data?.appliedDate,
  //           applicationId: res.data?.applicationId,
  //         });
  //         removeItemFromStorageItemWithArrayValue(storageKey.SAVED_JOBS, data);
  //         setAppliedJob(true);
  //         onJobApplied();
  //       }
  //       setIsApplying(false);
  //     }
  //   }
  // };

  const handleApplyJob = async (e) => {
    e.stopPropagation();
    
    if (data.isExternalJob) {
      const vejm = getStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL);
      if (vejm === false) {
        // Open the job URL directly and bypass the modal
        data?.url && window.open(data.url, "_blank");
      } else {
        // Show the modal since vejm is true
        setShowExternalJobModal(true);
      }
    } else {
      if (!user?.resume) {
        setShowAddResumeModal(true);
      } else {
        setIsApplying(true);
        let res = await applyJob({ job: data });
        if (res.status === "OK") {
          updateStorageItemWithArrayValue(storageKey.APPLIED_JOBS, {
            ...data,
            appliedDate: res.data?.appliedDate,
            applicationId: res.data?.applicationId,
          });
          removeItemFromStorageItemWithArrayValue(storageKey.SAVED_JOBS, data);
          setAppliedJob(true);
          onJobApplied();
        }
        setIsApplying(false);
      }
    }
  };
  

  return (
    <>
      <ModalWrapper
        {...props}
        title="Job detail view"
        jobtitle={data?.title}
        url={data?.url}
        detailPage={true}
        modalClass="max-md:bottom-0 max-md:top-unset max-md:translate-y-0 max-md:px-0"
        className={`${showAddResumeModal ? "!bg-modal-backdrop-light" : ""} ${props.className || ""
          }`}
        onCrossClick={onCrossClick}>
        <div className="flex-1 pb-20 overflow-y-scroll mt-7 hide-scrollbar md:max-h-job-detail-modal-d max-h-job-detail-modal-m">
          <p className="mb-4 text-lg font-bold text-black">{data?.title}</p>
          <JobLogoAndAddress {...data} />
          <JobTags data={data} tagClassName="mt-2" className="-ml-1.5 mb-3" />

          {data?.isExternalJob && <JobIsExternal />}
          <p className="pb-2 text-base font-semibold text-black">Job Description</p>
          <HtmlRenderer html={data?.description} />

          {!!data?.responsibilities && (
            <>
              <p className="pt-4 pb-2 text-base font-semibold text-black">
                Responsibilities
              </p>
              <HtmlRenderer html={data.responsibilities} />
            </>
          )}

          {!!data?.requirements && (
            <>
              <p className="pt-4 pb-2 text-base font-semibold text-black">Requirements</p>
              <HtmlRenderer html={data.requirements} />
            </>
          )}

          {!!data?.employer?.about && (
            <>
              <p className="pt-4 pb-2 text-base font-semibold text-black">
                About the company
              </p>
              <HtmlRenderer html={data.employer.about} />
            </>
          )}
        </div>
        <div className="absolute flex w-full pt-5 bg-white border-t border-gray-200 -bottom-1">
          <button className="mr-4" onClick={handleSaveJob}>
            {savedJob ? <Icons.SvgHeartButtonActive /> : <Icons.SvgHeartButton />}
          </button>
          <button
            className="flex items-center justify-center py-2 mb-2 rounded grow bg-primary-blue"
            onClick={handleApplyJob}
            disabled={!data?.isExternalJob && appliedJob}>
            {appliedJob && <Icons.SvgCheckMark className="mr-2 text-white" />}
            <p className="text-base font-semibold text-white uppercase">
              {appliedJob ? "Applied!" : `Apply${isApplying ? "ing..." : ""}`}
            </p>
          </button>
        </div>
      </ModalWrapper>
      <JobExternalModal
        data={data}
        isVisible={showExternalJobModal}
        onCrossClick={() => setShowExternalJobModal(false)}
      />
      <AddResumeModal
        isVisible={showAddResumeModal}
        currentJob={data}
        onCrossClick={() => setShowAddResumeModal(false)}
        onApplyClick={(e) => {
          handleApplyJob(e);
          setShowAddResumeModal(false);
        }}
      />
    </>
  );
};

export default JobDetailModal;
