import React from "react";
import { diffInDaysOfTwoDates } from "../../helpers/utils";
import JobLogoAndAddress from "./JobLogoAndAddress";
import JobTags from "./JobTags";
import JobIsExternal from "./JobIsExternal";
import HtmlRenderer from "../blocks/HtmlRenderer";
import ShareButtonsMenu from "../../components/ShareButtonsMenu";

export default function JobCardSingle(props) {
  const { data, className = "" } = props;

  const currentDate = new Date().toISOString();
  const postedDate = data.posted_at || data.updatedAt;
  const postedDays = diffInDaysOfTwoDates(currentDate, postedDate);


  return (
    <div
      className={`relative px-4 py-6 overflow-hidden bg-white border border-gray-300 rounded-lg h-full w-full md:max-h-card-single max-h-96 ${className} pb-10`} >
    
        {/* Share Button */}
      <div className="absolute top-4 right-4">
        <ShareButtonsMenu url={data.url} title={data.title} />
      </div>

      <div className="pb-4">
        <p className="mb-1 text-xs font-medium text-gray-500">
          {postedDays > 0
            ? `${postedDays} Day${postedDays > 1 ? "s" : ""} ago`
            : "Today"}
        </p>
        <p className="text-lg font-bold text-black">{data.title}</p>
      </div>
      <JobLogoAndAddress {...data} />
      <JobTags data={data} tagClassName="mt-2" className="mb-4" />
      {data.isExternalJob && <JobIsExternal />}

      {data.description && (
        <div className="flex-1 overflow-hidden mb-6">
          <p className="pb-2 font-semibold text-black p-base">
            Job Description
          </p>
          <p className="text-gray-700">
            {data.description.substring(0, 300)}...
          </p>
          <HtmlRenderer
            html={
              data.description.substring(0, 200) +
              `${data.jobDescription?.length > 200 ? "..." : ""}`
            }
          />
        </div>
      )}
    </div>
  );
}
