import JobLogoAndAddress from "./JobLogoAndAddress";
import JobIsExternal from "./JobIsExternal";
import JobTags from "./JobTags";
import { diffInDaysOfTwoDates, getStorageItem, storageKey } from "../../helpers/utils";
import useApplicationContext from "../../context/ApplicationContext";
import ShareButtonsMenu from "../../components/ShareButtonsMenu";


export default function JobCardGrid(props) {
  const {
    data,
    className = "",
    onIgnore = () => {},
    onApply = () => {},
    onClick = () => {},
    setSwipedItem = () => {},
    setShowAddResumeModal = () => {},
    setSwipeDir = () => {},
  } = props;
  const { user } = useApplicationContext();
  const currentDate = new Date().toISOString();
  const postedDate = data.posted_at || data.updatedAt;
  const postedDays = diffInDaysOfTwoDates(currentDate, postedDate);

  const handleApplyToJob = async () => {
    const vejm = getStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL);
    const isExternalJobPosting = !!data?.isExternalJob ?? false;

    if (isExternalJobPosting && vejm === false) {
      if (data?.url) {
        window.open(data.url, "_blank", "noopener noreferrer");
      }
    } else if (user?.resume || isExternalJobPosting) {
      onApply();
    } else {
      setShowAddResumeModal(true);
      setSwipedItem(data);
      setSwipeDir("--");
    }
  };

 

  return (
    <div className={`h-full w-full`}>
      <div
        className={`p-4 bg-white border border-gray-300 rounded-lg cursor-pointer ${className}`}
        onClick={onClick}>
        <div className="flex items-center justify-between">
          <p className="hidden mb-1 text-xs font-medium text-gray-500 md:block">
            {postedDays > 0 ? `${postedDays} Day${postedDays > 1 ? "s" : ""} ago` : "Today"}
          </p>

         {/* Share Buttons Menu */}
         <ShareButtonsMenu url={data.url} title={data.title} />
        </div>

        <p className="mb-4 text-base font-bold text-black truncate">{data.title}</p>
        <JobLogoAndAddress logoClassName="w-8 h-8" addressClassName="text-xs" {...data} />
        <JobTags data={data} maxShow={data.isExternalJob ? 1 : 2} tagClassName="mt-2" />
        {data.isExternalJob && <JobIsExternal className="!mb-0 mt-5" />}
      </div>

      <div className="flex items-center justify-between mt-4">
        <button
          onClick={onIgnore}
          className="flex items-center gap-2 px-3 font-medium text-gray-500 transition duration-300 border-2 rounded-3xl border-red hover:bg-red/10 hover:text-gray-900">
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.28125 1.28125L9.71875 9.71875M1.28125 9.71875L9.71875 1.28125"
              stroke="#E8503C"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Ignore
        </button>
        <button
          onClick={handleApplyToJob}
          className="flex items-center gap-2 px-3 font-medium text-gray-500 transition duration-300 border-2 rounded-3xl border-primary-blue hover:bg-primary-blue/10 hover:text-gray-900">
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.238 1.87988L4.60213 7.47988L1.78418 4.67988"
              stroke="#1C7FB9"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Apply
        </button>
      </div>
    </div>
  );
}
