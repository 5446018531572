import React, { useEffect } from "react";
import Icons from "../../icons";
import ShareButtonsMenu from "../../components/ShareButtonsMenu";


const ModalWrapper = ({
  children,
  title = "",
  isVisible = false,
  onCrossClick = () => {},
  modalClass = "",
  className = "",
  detailPage = false,
  url = "",
  jobtitle,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "");
  }, []);

  const handleOnCrossClick = (e) => {
    e.stopPropagation();
    onCrossClick();
  };

 
 if (!isVisible) return null;

  return (
    <div
      className={`modal fixed top-0 left-0 w-screen h-screen bg-modal-backdrop ${className}`}
      style={{ zIndex: 10000 }}
    >
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full md:max-h-75vh max-w-modal-wrapper max-md:px-6 ${modalClass}`}
      >
        <div className="p-4 bg-white rounded md:p-6">
          <div className="flex items-center justify-between">
            {!!title && (
              <p className="text-sm font-semibold text-gray-600">{title}</p>
            )}

            {detailPage ? (
              <div className="flex items-center space-x-4">


                {/* ShareButtonsMenu Component */}
                <ShareButtonsMenu url={url} jobtitle={jobtitle} />

            

                {/* Close Icon */}
                <Icons.SvgCrossMark
                  height={26}
                  onClick={handleOnCrossClick}
                  className="cursor-pointer"
                />
              </div>
            ) : (
              <Icons.SvgCrossMark
                height={26}
                onClick={handleOnCrossClick}
                className="ml-auto cursor-pointer"
              />
            )}
          </div>
          <div className="relative flex flex-col h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
