/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import Spinner from '../common/blocks/Spinner';
import SwipeableCard from '../common/blocks/SwipeableCard';
import SwipeDirectionInfo from '../common/blocks/SwipeDirectionInfo';
import SwipeToast from '../common/blocks/SwipeToast';
import Tooltip from '../common/blocks/Tooltip';
import FilterTags from '../common/jobs/FilterTags';
import JobCardGrid from '../common/jobs/JobCardGrid';
import JobCardSingle from '../common/jobs/JobCardSingle';
import AddResumeModal from '../common/modals/AddResumeModal';
import JobDetailModal from '../common/modals/JobDetailModal';
import JobExternalModal from '../common/modals/JobExternalModal';
import SuccessModal from '../common/modals/SuccessModal';
import useApplicationContext from '../context/ApplicationContext';
import {
  applyJob,
  getStorageItem,
  removeItemFromStorageItemWithArrayValue,
  setStorageItem,
  storageKey,
  updateStorageItemWithArrayValue
} from '../helpers/utils';
import useBreakpoint from '../hooks/useBreakpoint';
import Icons from '../icons';
import NoJobsBanner from './NoJobsBanner';
import InfiniteScroll from "react-infinite-scroll-component";
import { data } from 'autoprefixer';


const JobsListing = ({ OnMobileFilterTagsClick = () => { }, isSidebarOpen }) => {

  const PAGE_SIZE = 10;
const DEFAULT_LATITUDE = 43.6532;
const DEFAULT_LONGITUDE = -79.3823;
const [pageIndex, setPageIndex] = useState(1);
const [isExternalJobModalShown, setIsExternalJobModalShown] = useState(false);


const getJobData = async (pageNumber = 1, pageRerender) => {
    const serverURL = process.env.REACT_APP_GET_JOBS_API_URL;

    const localUserData = getStorageItem(storageKey.USER) || {};

    if (!localUserData) return;
    try {
      const distance = localUserData?.distance;
      const state=   localUserData?.location?.value?.terms[localUserData?.location.value.terms.length-2].value;
      const city = localUserData?.location?.label.split(',')[0];
      const contractTime = localUserData?.availabilities
        .filter((item) => item.value)
        .map((item) => item?.title.replace(' ', '_').toLowerCase());
      const categories = localUserData?.jobCategories
        .filter((item) => item?.value)
        .map((item) => item?.title);
      const queryParams = new URLSearchParams({
        state: state,
        pageSize: PAGE_SIZE,
        city: city,
        geo_lat: localUserData?.location?.latitude || DEFAULT_LATITUDE,
        geo_lng: localUserData?.location?.longitude || DEFAULT_LONGITUDE,
        page: pageNumber,
        mileDistance: distance || 40,
      });

      categories?.forEach((category) =>
        queryParams.append('category[]', category)
      );
      contractTime?.forEach((contractTime) =>
        queryParams.append('contractTime[]', contractTime)
      );
      const queryString = queryParams.toString().replace(/%5B%5D/g, '[]').replace(/\+/g, '%20')

      const rawResponse = await fetch(
        `${serverURL}?${queryString}`
      );
      let jobs = {};
       jobs = await rawResponse.json();
       setPageIndex((pageIndex) => pageIndex + 1);
       const jobsWithExternalFlag = jobs.results.map(job => ({
        ...job,
        isExternalJob: true
      }));
    return {
      isLoading: false,
      jobData: jobsWithExternalFlag,
      totalJobs: jobs.total,
    };
    } catch (error) {
      console.error('Error while fetching jobs', error);
  }
};


  const isDesktop = useBreakpoint('md');
  const swipeRef = useRef(null);
  const { user, refreshJobsData } = useApplicationContext();
  const ignoredJobs = getStorageItem(storageKey.IGNORED_JOBS) || [];
  const appliedJobs = getStorageItem(storageKey.APPLIED_JOBS) || [];

  const [currentPage, setCurrentPage] = useState(1);
  const[isLoading, setIsLaoding] = useState(true);
  const [jobData, setJobData] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);


  const [list, setList] = useState([]);
  const [firstItem, setFirstItem] = useState({});
  const [savedExternalJob, setSavedExternalJob] = useState(null);
  const [swipeDir, setSwipeDir] = useState('--');
  const [swipedItem, setSwipedItem] = useState(null);
  const [pressedItem, setPressedItem] = useState(null);
  const [gridView, setGridView] = useState(false);
  const [showJobDetailModal, setShowJobDetailModal] = useState(false);
  const [showExternalJobModal, setShowExternalJobModal] = useState(false);
  const [showAddResumeModal, setShowAddResumeModal] = useState(false);
  const [showAppliedModal, setShowAppliedModal] = useState(false);
  const [totalJobArrayLength, setTotalJobArrayLength] = useState(0);
  const [jobIndex, setJobIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [jobCount, setJobCount] = useState(1);

  const CheckIcon = firstItem?.isExternalJob
    ? Icons.SvgCheckCircleEx
    : Icons.SvgCheckCircle;

  const removeCard = (job) => {
    
    let remaining = list.filter((item) => !(item.id === job.id));
    setJobIndex(jobIndex + 1);
    setList(remaining);
    if (remaining.length === 0 && totalJobArrayLength > list.length) {
      console.log("page index in remove card", pageIndex, currentPage);
      setJobCount(jobCount + 1);
      setCurrentPage(currentPage + 1);

    }
  };

  const lastSwipedDirection = async (swipeDir, item, swipeDuration = 750) => {
    if (!item || swipeDir === '--') return;
 
  // Condition to handle right swipe on external job
  if (swipeDir === 'right' && item.isExternalJob) {
    const vejm = getStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL);

   
    if (vejm === false) {
      // If vejm is false, open the job URL directly
      item?.url && window.open(item.url, '_blank');
    } else {
      // Otherwise, show the external job modal
      setSavedExternalJob(item);
      setShowExternalJobModal(true);
    }

    // Remove the job card
    removeCard(item);
    return;
  }

    setSwipeDir(swipeDir);
    if (swipeDir === 'left') {
      console.log(item);
      setSwipedItem(item);
      setTimeout(() => {
        updateStorageItemWithArrayValue(storageKey.IGNORED_JOBS, item);
        removeCard(item);
      }, swipeDuration);
    }

    if (swipeDir === 'right') {
      setSwipedItem(item);
      await handleApplyJob(item);
      removeItemFromStorageItemWithArrayValue(storageKey.SAVED_JOBS, item);
      removeCard(item);
    }
  };

  const handleApplyJob = async (item) => {
    let res = await applyJob({ job: item });
    if (res.status === 'OK') {
      setTimeout(() => {
        updateStorageItemWithArrayValue(storageKey.APPLIED_JOBS, {
          ...item,
          appliedDate: res.data?.appliedDate,
          applicationId: res.data?.applicationId,
        });
        removeCard(item);
        // This is just testing to see what jobs are being applied to
        // console.log("Applied Job", item?.objectId);
      }, 10);
    } else {
      setSwipedItem(null);
      swipeRef?.current?.bounceBack();
    }
  };

  let filteredJobs = [];
    filteredJobs = jobData?.filter(job =>
      !ignoredJobs.some(ij => ij.id === job.id) &&
      !appliedJobs.some(aj => aj.id === job.id) &&
      job.title
    );

  const fetchMoreData = async() => {
    try{
        setTimeout(async() => {
          const jobDataResponse = await getJobData(pageIndex, false);
          if (jobDataResponse.jobData?.length > 0){
            setList((list) => [...list ,...jobDataResponse.jobData]);
          }else{
             console.log("reached end");
          }
          if(jobDataResponse.jobData?.length < 10){
            setHasMore(false);
          }
        }, 2000);
    }catch(err){
      console.log(err)
    } 
  };

  // const renderCards = useMemo(() => {
  //   return !isLoading && list?.length === 0 ? (
  //     <NoJobsBanner />
  //   ) : (
  //       <div 
  //       style={{scrollbarWidth: 'none'}} 
  //       className='flex-1 w-full pb-10 px-4 -mb-1 overflow-x-hidden overflow-y-auto bg-gray-100 md:px-16'>
  //       {!!!gridView && <SwipeDirectionInfo className='py-4 md:pt-6' />}
  //         <div
  //         className={`text-gray-600 md:text-left text-center ${list?.length === 0
  //             ? 'flex items-center justify-center h-[80%]'
  //             : 'my-4'
  //           }`}
  //       >
  //         {isLoading ? (
  //             <div className='flex flex-1 justify-center items-center w-full'>
  //               <Spinner />
  //           </div>
  //         ) : gridView ? (
  //               // `${list?.length || 0} ${list?.length >= 1 ? 'jobs' : 'job'} found`
  //               `${totalJobArrayLength} ${totalJobArrayLength >= 1 ? 'jobs' : 'job'} found`
  //         ) : (
  //           `${(jobCount - 1) * 10 + jobIndex} / ${totalJobArrayLength} Jobs`
  //         )}
          
  //         </div>
  //       {!!list?.length && !isLoading && (
  //         <>
  //           <div id="scrollableDiv">
  //           <InfiniteScroll
  //           dataLength={pageIndex * 10}
  //           next={() => fetchMoreData()}
  //           hasMore={hasMore}
  //           scrollThreshold={0.9}
  //           loader={gridView ? <Spinner/> : null}
  //           scrollableTarget="scrollableDiv"
  //           height={'95vh'}
  //           >
  //             <div
  //               className={`relative ${gridView
  //                 ? `grid ${!!isSidebarOpen ? 'md:grid-cols-2' : 'md:grid-cols-3'
  //                 } grid-cols-2 md:gap-9 gap-4 md:mb-10 mb-6`
  //                 : 'max-w-card-single mx-auto h-full md:max-h-card-single max-h-96'
  //                 }`}
  //             >
  //               {!!list?.length &&
  //                 list.map((item, idx) => (
  //                   <div
  //                     key={item.id + idx.toString()}
  //                     style={{ zIndex: gridView ? null : list.length - idx }}
  //                     className={`${gridView ? 'relative' : 'absolute'
  //                       } h-full w-full`}
  //                   >
  //                     {!!gridView ? (
  //                       <JobCardGrid
  //                         data={item}
  //                         onIgnore={() =>
  //                           lastSwipedDirection('left', item, 100, idx)
  //                         }
  //                         onApply={() =>
  //                           lastSwipedDirection('right', item, 100, idx)
  //                         }
  //                         setShowAddResumeModal={setShowAddResumeModal}
  //                         setSwipedItem={setSwipedItem}
  //                         setSwipeDir={setSwipeDir}
  //                         onClick={() => {
  //                           if (!!!item.isExternalJob) {
  //                             setShowJobDetailModal(true);
  //                             setPressedItem(item);
  //                           } else {
  //                             setShowJobDetailModal(true);
  //                             setPressedItem(item);
  //                             // window.open(item.jobUrl, '_BLANK');
  //                           }
  //                         }}
  //                       />
  //                     ) : (
  //                       <SwipeableCard
  //                         ref={idx === 0 ? swipeRef : null}
  //                         item={item}
  //                         onSwipeLeft={(item) =>
  //                           lastSwipedDirection('left', item, 100, idx)
  //                         }
  //                         onSwipeRight={(item) =>
  //                           lastSwipedDirection('right', item, 100, idx)
  //                         }
  //                         total={list.length}
  //                         className='flex-1'
  //                         setShowAddResumeModal={setShowAddResumeModal}
  //                         setSwipedItem={setSwipedItem}
  //                         setSwipeDir={setSwipeDir}
  //                         onClick={() => {
  //                           if (!!!item.isExternalJob) {
  //                             setShowJobDetailModal(true);
  //                             setPressedItem(item);
  //                           } else {
  //                             setShowJobDetailModal(true);
  //                             setPressedItem(item);
  //                           }
  //                         }}
  //                       >
  //                         <JobCardSingle data={item} />
  //                       </SwipeableCard>
  //                     )}
  //                     {swipedItem?.id === item.id && swipeDir !== '--' && (
  //                       <SwipeToast
  //                         swipeDir={swipeDir}
  //                         swipeLeftMessage={!gridView ? 'NOT INTERESTED' : ''}
  //                         swipeRightMessage='APPLIED'
  //                       />
  //                     )}
  //                   </div>
  //                 ))}
  //             </div>
  //           </InfiniteScroll>
  //           </div>
  //           {!gridView && (
  //             <div
  //               style={{ zIndex: list.length + 10 }}
  //                 className='sticky bottom-0 z-50 grid justify-center grid-cols-2 pb-4 mx-auto md:gap-9 gap-14 md:mt-20 mt-14 max-w-card-single'
  //             >
  //               <button
  //                 className='flex items-center justify-end cursor-pointer md:justify-center md:flex-1 md:py-2 md:bg-white md:border-2 md:rounded-full text-red md:border-red'
  //                 onClick={() => swipeRef?.current?.swipeLeft()}
  //               >
  //                 <Icons.SvgCrossCircleMobile
  //                   className='md:h-6 md:w-6 h-14 w-14'
  //                   strokeWidth={isDesktop ? 2 : 1}
  //                 />
  //                 <p className='hidden ml-2 text-sm font-bold md:block'>
  //                   NOT INTERESTED
  //                 </p>
  //               </button>
  //               <button
  //                 className={`flex items-center md:justify-center md:flex-1 md:py-2 md:rounded-full cursor-pointer ${firstItem?.isExternalJob
  //                   ? 'bg-external-job text-white'
  //                   : 'md:bg-white text-primary-blue md:border-2 md:border-primary-blue'
  //                   }`}
  //                   onClick={() => {
  //                     if (firstItem?.isExternalJob) {
  //                       const vejm = getStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL);
  //                       if (vejm === false) {
  //                         // Directly open the external job URL
  //                         firstItem?.url && window.open(firstItem.url, "_blank");
  //                         swipeRef?.current?.swipeRight(); // Swipe the card after opening the URL
  //                       } else {
  //                         // Show the modal and swipe after user interaction
  //                         setSavedExternalJob(firstItem);
  //                         setShowExternalJobModal(true);
  //                       }
  //                     } else {
  //                       // Directly swipe if it's not an external job
  //                       swipeRef?.current?.swipeRight();
  //                     }
  //                   }}
  //                 >
                
  //                 {isDesktop && (
  //                   <>
  //                     <Icons.SvgCheckMark />
  //                     <p className='hidden ml-2 text-sm font-bold md:block'>
  //                       APPLY {firstItem?.isExternalJob ? 'FROM LINK' : ''}
  //                     </p>
  //                   </>
  //                 )}
  //                 {!isDesktop && (
  //                   <CheckIcon
  //                     className='h-14 w-14'
  //                     strokeWidth={1}
  //                   />
  //                 )}
  //               </button>
  //             </div>
  //           )}
  //         </>
  //       )}
  //     </div>
  //   );
  // }, [
  //   currentPage,
  //   isLoading,
  //   jobIndex,
  //   list,
  //   swipeDir,
  //   swipedItem?.id,
  //   totalJobs,
  //   gridView,
  //   totalJobArrayLength
  // ]);


  // const renderCards = useMemo(() => {
  //   // Determine the first item from the list
  //   const firstItem = list && list.length > 0 ? list[0] : null;
  
  //   return !isLoading && list?.length === 0 ? (
  //     <NoJobsBanner />
  //   ) : (
  //     <div
  //       style={{ scrollbarWidth: 'none' }}
  //       className='flex-1 w-full pb-10 px-4 -mb-1 overflow-x-hidden overflow-y-auto bg-gray-100 md:px-16'
  //     >
  //       {!gridView && <SwipeDirectionInfo className='py-4 md:pt-6' />}
  //       <div
  //         className={`text-gray-600 md:text-left text-center ${
  //           list?.length === 0
  //             ? 'flex items-center justify-center h-[80%]'
  //             : 'my-4'
  //         }`}
  //       >
  //         {isLoading ? (
  //           <div className='flex flex-1 justify-center items-center w-full'>
  //             <Spinner />
  //           </div>
  //         ) : gridView ? (
  //           `${totalJobArrayLength} ${totalJobArrayLength >= 1 ? 'jobs' : 'job'} found`
  //         ) : (
  //           `${(jobCount - 1) * 10 + jobIndex} / ${totalJobArrayLength} Jobs`
  //         )}
  //       </div>
  //       {!!list?.length && !isLoading && (
  //         <>
  //           <div id="scrollableDiv">
  //             <InfiniteScroll
  //               dataLength={pageIndex * 10}
  //               next={() => fetchMoreData()}
  //               hasMore={hasMore}
  //               scrollThreshold={0.9}
  //               loader={gridView ? <Spinner /> : null}
  //               scrollableTarget="scrollableDiv"
  //               height={'95vh'}
  //             >
  //               <div
  //                 className={`relative ${
  //                   gridView
  //                     ? `grid ${!!isSidebarOpen ? 'md:grid-cols-2' : 'md:grid-cols-3'} grid-cols-2 md:gap-9 gap-4 md:mb-10 mb-6`
  //                     : 'max-w-card-single mx-auto h-full md:max-h-card-single max-h-96'
  //                 }`}
  //               >
  //                 {list.map((item, idx) => (
  //                   <div
  //                     key={item.id + idx.toString()}
  //                     style={{ zIndex: gridView ? null : list.length - idx }}
  //                     className={`${gridView ? 'relative' : 'absolute'} h-full w-full`}
  //                   >
  //                     {gridView ? (
  //                       <JobCardGrid
  //                         data={item}
  //                         onIgnore={() => lastSwipedDirection('left', item, 100, idx)}
  //                         onApply={() => lastSwipedDirection('right', item, 100, idx)}
  //                         setShowAddResumeModal={setShowAddResumeModal}
  //                         setSwipedItem={setSwipedItem}
  //                         setSwipeDir={setSwipeDir}
  //                         onClick={() => {
  //                           setShowJobDetailModal(true);
  //                           setPressedItem(item);
  //                         }}
  //                       />
  //                     ) : (
  //                       <SwipeableCard
  //                         ref={idx === 0 ? swipeRef : null}
  //                         item={item}
  //                         onSwipeLeft={(item) => lastSwipedDirection('left', item, 100, idx)}
  //                         onSwipeRight={(item) => lastSwipedDirection('right', item, 100, idx)}
  //                         total={list.length}
  //                         className='flex-1'
  //                         setShowAddResumeModal={setShowAddResumeModal}
  //                         setSwipedItem={setSwipedItem}
  //                         setSwipeDir={setSwipeDir}
  //                         onClick={() => {
  //                           setShowJobDetailModal(true);
  //                           setPressedItem(item);
  //                         }}
  //                       >
  //                         <JobCardSingle data={item} />
  //                       </SwipeableCard>
  //                     )}
  //                     {swipedItem?.id === item.id && swipeDir !== '--' && (
  //                       <SwipeToast
  //                         swipeDir={swipeDir}
  //                         swipeLeftMessage={!gridView ? 'NOT INTERESTED' : ''}
  //                         swipeRightMessage='APPLIED'
  //                       />
  //                     )}
  //                   </div>
  //                 ))}
  //               </div>
  //             </InfiniteScroll>
  //           </div>
  //           {!gridView && (
  //             <div
  //               style={{ zIndex: list.length + 10 }}
  //               className='sticky bottom-0 z-50 grid justify-center grid-cols-2 pb-4 mx-auto md:gap-9 gap-14 md:mt-20 mt-14 max-w-card-single'
  //             >
  //               <button
  //                 className='flex items-center justify-end cursor-pointer md:justify-center md:flex-1 md:py-2 md:bg-white md:border-2 md:rounded-full text-red md:border-red'
  //                 onClick={() => swipeRef?.current?.swipeLeft()}
  //               >
  //                 <Icons.SvgCrossCircleMobile
  //                   className='md:h-6 md:w-6 h-14 w-14'
  //                   strokeWidth={isDesktop ? 2 : 1}
  //                 />
  //                 <p className='hidden ml-2 text-sm font-bold md:block'>
  //                   NOT INTERESTED
  //                 </p>
  //               </button>
  //               <button
  //                 className={`flex items-center md:justify-center md:flex-1 md:py-2 md:rounded-full cursor-pointer ${
  //                   firstItem?.isExternalJob
  //                     ? 'bg-external-job text-white'
  //                     : 'md:bg-white text-primary-blue md:border-2 md:border-primary-blue'
  //                 }`}
  //                 onClick={() => {
  //                   if (firstItem?.isExternalJob) {
  //                     const vejm = getStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL);
  //                     if (vejm === false) {
  //                       // Directly open the external job URL
  //                       firstItem?.url && window.open(firstItem.url, "_blank");
  //                       swipeRef?.current?.swipeRight(); // Swipe the card after opening the URL
  //                     } else {
  //                       // Show the modal and swipe after user interaction
  //                       setSavedExternalJob(firstItem);
  //                       setShowExternalJobModal(true);
  //                     }
  //                   } else {
  //                     // Directly swipe if it's not an external job
  //                     swipeRef?.current?.swipeRight();
  //                   }
  //                 }}
  //               >
  //                 {isDesktop && (
  //                   <>
  //                     <Icons.SvgCheckMark />
  //                     <p className='hidden ml-2 text-sm font-bold md:block'>
  //                       APPLY
  //                     </p>
  //                   </>
  //                 )}
  //                 {!isDesktop && (
  //                   <CheckIcon
  //                     className='h-14 w-14'
  //                     strokeWidth={1}
  //                   />
  //                 )}
  //               </button>
  //             </div>
  //           )}
  //         </>
  //       )}
  //     </div>
  //   );
  // }, [
  //   currentPage,
  //   isLoading,
  //   jobIndex,
  //   list,
  //   swipeDir,
  //   swipedItem?.id,
  //   totalJobs,
  //   gridView,
  //   totalJobArrayLength
  // ]);
  


  const renderCards = useMemo(() => {
    const firstItem = list && list.length > 0 ? list[0] : null;
  
    return !isLoading && list?.length === 0 ? (
      <NoJobsBanner />
    ) : (
      <div
        style={{ scrollbarWidth: 'none' }}
        className='flex-1 w-full pb-10 px-4 -mb-1 overflow-x-hidden overflow-y-auto bg-gray-100 md:px-16'
      >
        {!gridView && <SwipeDirectionInfo className='py-4 md:pt-6' />}
        <div
          className={`text-gray-600 md:text-left text-center ${
            list?.length === 0
              ? 'flex items-center justify-center h-[80%]'
              : 'my-4'
          }`}
        >
          {isLoading ? (
            <div className='flex flex-1 justify-center items-center w-full'>
              <Spinner />
            </div>
          ) : gridView ? (
            `${totalJobArrayLength} ${totalJobArrayLength >= 1 ? 'jobs' : 'job'} found`
          ) : (
            `${(jobCount - 1) * 10 + jobIndex} / ${totalJobArrayLength} Jobs`
          )}
        </div>
        {!!list?.length && !isLoading && (
          <>
            <div id="scrollableDiv">
              <InfiniteScroll
                dataLength={pageIndex * 10}
                next={() => fetchMoreData()}
                hasMore={hasMore}
                scrollThreshold={0.9}
                loader={gridView ? <Spinner /> : null}
                scrollableTarget="scrollableDiv"
                height={'95vh'}
              >
                <div
                  className={`relative ${
                    gridView
                      ? `grid ${!!isSidebarOpen ? 'md:grid-cols-2' : 'md:grid-cols-3'} grid-cols-2 md:gap-9 gap-4 md:mb-10 mb-6`
                      : 'max-w-card-single mx-auto h-full md:max-h-card-single max-h-96'
                  }`}
                >
                  {list.map((item, idx) => (
                    <div
                      key={item.id + idx.toString()}
                      style={{ zIndex: gridView ? null : list.length - idx }}
                      className={`${gridView ? 'relative' : 'absolute'} h-full w-full`}
                    >
                      {gridView ? (
                        <JobCardGrid
                          data={item}
                          onIgnore={() => lastSwipedDirection('left', item, 100, idx)}
                          onApply={() => lastSwipedDirection('right', item, 100, idx)}
                          setShowAddResumeModal={setShowAddResumeModal}
                          setSwipedItem={setSwipedItem}
                          setSwipeDir={setSwipeDir}
                          onClick={() => {
                            setShowJobDetailModal(true);
                            setPressedItem(item);
                          }}
                        />
                      ) : (
                        <SwipeableCard
                          ref={idx === 0 ? swipeRef : null}
                          item={item}
                          onSwipeLeft={(item) => lastSwipedDirection('left', item, 100, idx)}
                          onSwipeRight={(item) => lastSwipedDirection('right', item, 100, idx)}
                          total={list.length}
                          className='flex-1'
                          setShowAddResumeModal={setShowAddResumeModal}
                          setSwipedItem={setSwipedItem}
                          setSwipeDir={setSwipeDir}
                          onClick={() => {
                            setShowJobDetailModal(true);
                            setPressedItem(item);
                          }}
                        >
                          <JobCardSingle data={item}  onCardClick={(clickedItem) => {
      setShowJobDetailModal(true);
      setPressedItem(clickedItem);
    }}  />
                        </SwipeableCard>
                      )}
                      {swipedItem?.id === item.id && swipeDir !== '--' && (
                        <SwipeToast
                          swipeDir={swipeDir}
                          swipeLeftMessage={!gridView ? 'NOT INTERESTED' : ''}
                          swipeRightMessage='APPLIED'
                        />
                      )}
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
            {!gridView && (
              <div
                style={{ zIndex: list.length + 10 }}
                className='sticky bottom-0 z-50 grid justify-center grid-cols-2 pb-4 mx-auto md:gap-9 gap-14 md:mt-20 mt-14 max-w-card-single'
              >
                <button
                  className='flex items-center justify-end cursor-pointer md:justify-center md:flex-1 md:py-2 md:bg-white md:border-2 md:rounded-full text-red md:border-red'
                  onClick={() => swipeRef?.current?.swipeLeft()}
                >
                  <Icons.SvgCrossCircleMobile
                    className='md:h-6 md:w-6 h-14 w-14'
                    strokeWidth={isDesktop ? 2 : 1}
                  />
                  <p className='hidden ml-2 text-sm font-bold md:block'>
                    Not Interested
                  </p>
                </button>
                <button
                  className={`flex items-center md:justify-center md:flex-1 md:py-2 md:rounded-full cursor-pointer ${
                    firstItem?.isExternalJob
                      ? 'bg-external-job text-white'
                      : 'md:bg-white text-primary-blue md:border-2 md:border-primary-blue'
                  }`}
                  onClick={() => {
                    if (firstItem?.isExternalJob) {
                      const vejm = getStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL);
                      if (vejm === false) {
                        // Directly open the external job URL
                        firstItem?.url && window.open(firstItem.url, "_blank");
                        // Set state to prevent swipe
                        setIsExternalJobModalShown(true);
                      } else {
                        // Show the modal and set state to prevent swipe
                        setSavedExternalJob(firstItem);
                        setShowExternalJobModal(true);
                        setIsExternalJobModalShown(true);
                      }
                    } else {
                      // Directly swipe if it's not an external job
                      swipeRef?.current?.swipeRight();
                    }
                  }}
                >
                  {isDesktop && (
                    <>
                      <Icons.SvgCheckMark />
                      <p className='hidden ml-2 text-sm font-bold md:block'>
                        Apply
                      </p>
                    </>
                  )}
                  {!isDesktop && (
                    <CheckIcon
                      className='h-14 w-14'
                      strokeWidth={1}
                    />
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }, [
    currentPage,
    isLoading,
    jobIndex,
    list,
    swipeDir,
    swipedItem?.id,
    totalJobs,
    gridView,
    totalJobArrayLength,
    isExternalJobModalShown // Add to dependency array
  ]);

  // Use an effect to handle post-modal actions
useEffect(() => {
  if (isExternalJobModalShown) {
    // Perform actions or cleanup after the modal has been shown
    // For example, reset the state
    setIsExternalJobModalShown(false);
  }
}, [isExternalJobModalShown]);

  useEffect(() => {
    let tmp = getStorageItem('gridView');
    if (!!tmp) {
      setGridView(tmp);
    } else {
      setStorageItem('gridView', gridView);
    }
  }, []);

  useEffect(() => {
    if (refreshJobsData) {
      setIsLaoding(true);
      console.log("gird view", gridView)
      const fetchData = async () => {
          const jobDataResponse = await getJobData(1, false);
          setList(jobDataResponse.jobData);
          setJobIndex(1);
          setTotalJobArrayLength(jobDataResponse.totalJobs);
            setPageIndex(2)
          setHasMore(true);
          setIsLaoding(jobDataResponse.isLoading);
        }
      fetchData();
    }
  }, [refreshJobsData])

  //first time
  useEffect(() => {
    const fetchData = async () => {
      if (isLoading) {
        console.log("---------------------------", pageIndex);
        const jobDataResponse = await getJobData(pageIndex, false);
        setList(jobDataResponse.jobData);
        setJobIndex(1);
        setTotalJobArrayLength(jobDataResponse.totalJobs);
        setIsLaoding(jobDataResponse.isLoading);
      }
    };
  
    fetchData();
  }, [currentPage]);


  //swipe cards
  useEffect(() => {
    setIsLaoding(true);

    if (currentPage && currentPage !== 1) {
      console.log("page index ...........", pageIndex, currentPage);

      const fetchData = async () => {
          const jobDataResponse = await getJobData(pageIndex, false);
          setList(jobDataResponse.jobData);
          setJobIndex(1);
          setTotalJobArrayLength(jobDataResponse.totalJobs);
          setIsLaoding(jobDataResponse.isLoading);
      };
      fetchData();
    }
  }, [currentPage]);

  useEffect(() => {
    setStorageItem('gridView', gridView);
  }, [gridView]);

  const handleGridChange = async(value) => {
      setIsLaoding(true);
          const jobDataResponse = await getJobData(1, true);
          setList(jobDataResponse.jobData);
          setJobIndex(1);
            setPageIndex(2);
          setTotalJobArrayLength(jobDataResponse.totalJobs);
          setHasMore(true);
          setIsLaoding(jobDataResponse.isLoading);
          setJobCount(1);
    setGridView(value);
    }


  return (
    <div className='relative flex flex-col w-full  bg-gray-100'>
      <div className='bg-white border-b border-gray-300'>
        {!isDesktop && (
          <div className='flex items-center py-2 pl-4 overflow-hidden border-b border-gray-300'>
            <p className='mr-4 text-gray-900'>Filter</p>
            <div
              className='pl-4 overflow-hidden border-l'
              onClick={OnMobileFilterTagsClick}
            >
              <FilterTags
                tagClassName='m-1'
                className='-ml-1.5 !flex-nowrap overflow-x-scroll hide-scrollbar'
              />
            </div>
          </div>
        )}
        <div className='flex items-center justify-between px-4 py-4 md:px-16 md:py-5'>
          <p className='font-semibold md:text-xl text-dark-blue'>All Jobs</p>
          <div className='flex'>
            <button
              className='relative mr-6 cursor-pointer'
              onClick={() => handleGridChange(false)}
            >
              <Icons.SvgCardView
                className={`${gridView ? 'text-gray-500' : 'text-primary-blue'
                  }`}
              />
              <Tooltip value='Standard&nbsp;View' />
            </button>
            <button
              className='relative cursor-pointer'
              onClick={() => handleGridChange(true)}
            >
              <Icons.SvgGridView
                className={`${!gridView ? 'text-gray-500' : 'text-primary-blue'
                  }`}
              />
              <Tooltip value='Grid&nbsp;View' />
            </button>
          </div>
        </div>
      </div>
      {renderCards}
      {/* {gridView && <div className='absolute bottom-0 py-5 mx-10 z-50 '> */}
        {/* <ResponsivePagination
          current={currentPage}
          total={Math.ceil(totalJobs / 10)}
          onPageChange={setCurrentPage}
          maxWidth={window.innerWidth * 0.9}
        /> */}
      {/* </div>} */}

      <JobDetailModal
        isVisible={showJobDetailModal}
        data={pressedItem}
        onCrossClick={() => {
          setShowJobDetailModal(false)
        }}
        onJobApplied={() => {
          removeCard(pressedItem);
          setShowAppliedModal(true);
        }}
      />
      <SuccessModal
        currentJob={pressedItem}
        isVisible={showAppliedModal}
        onCrossClick={() => setShowAppliedModal(false)}
      />
      <JobExternalModal
        isVisible={showExternalJobModal}
        onSwipe={() => swipeRef?.current?.swipeRight()} 
        data={savedExternalJob}
        onCrossClick={() => setShowExternalJobModal(false)}
      />
      <AddResumeModal
        isVisible={showAddResumeModal}
        currentJob={pressedItem}
        swipedItem={swipedItem}
        setSwipeDir={setSwipeDir}
        onCrossClick={() => setShowAddResumeModal(false)}
        onApplyClick={() => {
          handleApplyJob(swipedItem);
          setShowAddResumeModal(false);
        }}
      />
    </div>
  );
};

export default JobsListing;